/**
 * @function getColClasses
 * @param {number} index current column index
 * @param {number} colCount heading count
 * @param {boolean} stretchFirst stretch first column to full width
 * @param {number} gridWidth max column grid width (default: 12)
 * @description Gets the boostrap column class (col-*) for a specific grid item (ex: col-4)
 * @returns {string}
 */
var getColClasses = function (index, colCount, stretchFirst, gridWidth) {
    if (gridWidth === void 0) { gridWidth = 12; }
    var first = Boolean(index === 0);
    var colWidth = Math.floor(gridWidth / colCount);
    var remainder = gridWidth - colCount * colWidth;
    return stretchFirst
        ? // refactor to a simpler grid generation system, for example, possibly
            // pass specific grid widths without surpassing the max grid count
            (first && "col-" + (gridWidth - (colCount - 1) * 2)) || "col-2"
        : "col-" + (colWidth + (index < remainder ? 1 : 0));
};
export default getColClasses;
