/**
 * @function setTheme
 * @param {Document} document Document object
 * @param {ThemesEnum} theme Theme to set
 * @param {string} base Base path
 * @description Sets the theme css in the dom
 * @returns {boolean} true
 */
var setTheme = function (document, theme, base) {
    if (base === void 0) { base = "/themes/"; }
    var head = document.head;
    var themeCss = head.querySelector("link[data-theme]");
    if (themeCss) {
        themeCss.setAttribute("data-theme", theme);
        themeCss.setAttribute("href", "" + base + theme + "/index.css");
    }
    else {
        var link = document.createElement("link");
        link.setAttribute("rel", "stylesheet");
        link.setAttribute("type", "text/css");
        link.setAttribute("data-theme", theme);
        link.setAttribute("href", "" + base + theme + "/index.css");
        head.appendChild(link);
    }
    return true;
};
export default setTheme;
