var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import config from "../../config";
import IconEnum from "../../shared/enums/icon.enum";
import classMap from "../../shared/utilities/classMap";
import eventHelper from "../../shared/utilities/eventHelper";
import getColClasses from "../../shared/utilities/getColClasses";
import "./expanding-table.scss";
var ExpandingTable = function (props) {
    var _a = useState(props.activeKey), activeKey = _a[0], setActiveKey = _a[1];
    var excludes = ["body", "callback", "rowCap", "rowCapBody", "itemKey", "collapseClassName", "toggleClassName"];
    // duplicate 1st row cap to align the headings with table columns
    var firstRowCap = props.items && props.items.length && props.items[0].rowCap && (React.createElement("div", { style: { height: 1, visibility: "hidden" } }, props.items[0].rowCap));
    var tableClasses = classMap(props.className, "mpng-ux-expanding-table table", (props.striped && "table-striped") || undefined, (props.bordered && "table-bordered") || undefined, (props.separated && "table-separated") || undefined, (props.hover && "table-hover") || undefined);
    var excludeProps = function (props) {
        var itemProps = props;
        excludes.map(function (value) {
            delete itemProps[value];
            return Boolean(itemProps[value]);
        });
        return itemProps;
    };
    var buildPropertyMap = function (items) {
        // still need body and rowCap able to be looked up in the view
        var sanitized = {
            items: [],
            bodies: [],
            rowCaps: []
        };
        items.map(function (child, index) {
            var key = index + 1;
            var eventKey = String(child.itemKey || key);
            sanitized.items[eventKey] = excludeProps(__assign({}, child));
            sanitized.rowCaps[eventKey] = child.rowCap;
            sanitized.bodies[eventKey] = child.body;
            return sanitized;
        });
        return sanitized;
    };
    var propMap = buildPropertyMap(props.items);
    library.add(config.iconLibrary.solid.pack);
    var openIndicator = function (isActive, isShown) {
        if (isShown === void 0) { isShown = true; }
        return props.items.length &&
            props.items[0] &&
            props.items[0].body &&
            props.openIndicator && (React.createElement("span", { className: "open-indicator ml-2", style: { transform: isActive ? "rotate(90deg)" : undefined } },
            React.createElement(React.Fragment, null),
            React.createElement(FontAwesomeIcon, { icon: [config.iconLibrary.solid.style, IconEnum.CHEVRON_RIGHT] })));
    };
    var getCols = function (columns) {
        return columns.map(function (value, index) { return (React.createElement("div", __assign({}, {
            key: index,
            className: classMap("column-" + index, getColClasses(index, props.headings.length, props.stretchFirstCol))
        }), value)); });
    };
    var handleClick = function (key, callback, event) {
        event.stopPropagation();
        // set currently selected key or set to default (closed state) if same key selected again
        setActiveKey(key);
        // execute callback if supplied
        if (typeof callback === "function") {
            callback(key);
        }
    };
    return (React.createElement("div", { className: tableClasses },
        React.createElement("div", { className: "table-heading-container container mw-100 d-flex align-items-center px-0" },
            firstRowCap,
            React.createElement("div", { style: { visibility: "hidden" } }, openIndicator(false)),
            React.createElement("div", { className: "table-heading row " + (props.headingsClassName || "") }, getCols(props.headings))),
        React.createElement("div", { className: "table-body-container mw-100 p-0" }, props.items.map(function (child, index) {
            var key = index + 1;
            var eventKey = String(child.itemKey || key);
            var isActive = String(activeKey) === eventKey;
            var focused = String(props.focusedKey) === eventKey ? "focused" : "";
            var toggleClassName = child.toggleClassName || "";
            var rowCap = propMap.rowCaps[eventKey] || "";
            var body = propMap.bodies[eventKey] || "";
            var hasAction = body || child.callback;
            return (React.createElement("div", { className: "table-row row " + focused, key: key },
                React.createElement("div", { className: "container mw-100 d-flex align-items-center px-0" },
                    rowCap && React.createElement("div", { className: "table-row-cap" }, rowCap),
                    React.createElement("div", __assign({ id: "table-row-toggle-" + eventKey }, __assign({ className: "table-row-toggle " + toggleClassName + " " + focused + " " + (hasAction ? "cursor-pointer" : "") }, eventHelper(function (event) {
                        return handleClick(body && isActive ? "-1" : eventKey, child.callback, event);
                    }))),
                        openIndicator(isActive),
                        React.createElement("div", { className: "table-row-cols" }, getCols(Object.values(propMap.items[eventKey]))))),
                React.createElement("div", { className: "collapse-container " + (child.collapseClassName || "") }, isActive && body ? body : null)));
        }))));
};
export default ExpandingTable;
