var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import classMap from "../../shared/utilities/classMap";
import eventHelper from "../../shared/utilities/eventHelper";
import FaIcon from "../fa-icon";
import IconEnum from "../../shared/enums/icon.enum";
import SizeEnum from "../../shared/enums/size.enum";
var AccordionToggle = function (props) {
    var toggleAccordion = useAccordionToggle(props.eventKey, function () {
        if (typeof props.callback === "function") {
            props.callback(props.eventKey);
        }
    });
    return (React.createElement("div", __assign({}, eventHelper(toggleAccordion), { className: classMap("accordion-toggle d-flex", props.className) }),
        props.openIndicator && (React.createElement("div", { className: "toggle-icon mr-3" },
            React.createElement(FaIcon, { icon: props.activeKey === props.eventKey ? IconEnum.CHEVRON_DOWN : IconEnum.CHEVRON_RIGHT, squared: true, size: props.indicatorSize || SizeEnum.SMALL }))),
        React.createElement("div", { className: "toggle-title" }, props.body)));
};
export default AccordionToggle;
