import React from "react";
import * as SVGLoaders from "svg-loaders-react";
import PreloaderTypeEnum from "../../shared/enums/preloader-type.enum";
import PriorityEnum from "../../shared/enums/priority.enum";
import SizeEnum from "../../shared/enums/size.enum";
import classMap from "../../shared/utilities/classMap";
var Preloader = function (props) {
    var _a, _b;
    var type = (_a = props.type) !== null && _a !== void 0 ? _a : PreloaderTypeEnum.GRID;
    var Loader = SVGLoaders[type];
    return (React.createElement("div", { className: classMap("mpng-ux-preloader", type.toLowerCase(), "size", props.size || SizeEnum.SMALL, props.className) },
        React.createElement(Loader, { className: classMap("w-100 h-100", "fill fill-" + ((_b = props.fill) !== null && _b !== void 0 ? _b : PriorityEnum.PRIMARY), props.stroke ? "stroke stroke-" + props.stroke : ""), strokeOpacity: props.strokeOpacity || 1 })));
};
export default Preloader;
