var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import IconLibraryEnum from "../../shared/enums/icon-library.enum";
import IconEnum from "../../shared/enums/icon.enum";
import ShadesEnum from "../../shared/enums/shades.enum";
import classMap from "../../shared/utilities/classMap";
import ButtonIcon from "../button-icon";
import "./drawer.scss";
var Drawer = function (props) {
    var _a = useState(props.open), open = _a[0], setOpen = _a[1];
    var isOpen = function () { return (props.open !== undefined ? props.open : open); };
    return (React.createElement("div", { className: classMap("mpng-ux-drawer position-fixed top-0 shadow-sm", props.right ? "right-0" : "left-0", props.className) },
        // show toggle by default (when undefined)
        (props.showToggle || props.showToggle === undefined) && (React.createElement(ButtonIcon, __assign({}, {
            callback: function () { return setOpen(!open); },
            priority: ShadesEnum.LIGHT,
            icon: {
                library: IconLibraryEnum.LIGHT,
                icon: (!props.right && !isOpen()) || (props.right && isOpen())
                    ? IconEnum.CHEVRON_RIGHT
                    : IconEnum.CHEVRON_LEFT
            },
            className: classMap("drawer-toggle mt-5 py-3", "position-absolute top-0", "animation shadow-hover", "bg-white z-index--1", props.right ? "right pr-3 pl-2" : "left pr-2 pl-3", props.className)
        }))),
        React.createElement(Collapse, { in: isOpen(), dimension: "width" },
            React.createElement("div", { className: "animation" },
                React.createElement("div", { className: classMap("bg-light vh-100 p-3", "size " + (props.size || "sm")) }, props.child)))));
};
export default Drawer;
