var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef, useState } from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import IconLibraryEnum from "../../shared/enums/icon-library.enum";
import IconEnum from "../../shared/enums/icon.enum";
import SizeEnum from "../../shared/enums/size.enum";
import eventHelper from "../../shared/utilities/eventHelper";
import BrandedText from "../branded-text";
import Icon from "../icon";
import "./logo-text.scss";
var LogoText = function (props) {
    var spanRef = useRef(null);
    var divRef = useRef(null);
    var _a = useState(false), showTooltip = _a[0], setShowTooltip = _a[1];
    var _b = useState(false), showOverflowIcon = _b[0], setShowOverflowIcon = _b[1];
    var isMounted = useRef(false);
    var isEllipsisActive = function () {
        var offsetWidth = 0;
        var scrollWidth = 0;
        var ref = spanRef.current && spanRef.current.parentElement ? spanRef.current.parentElement.parentElement : spanRef.current;
        if (ref) {
            offsetWidth = ref ? ref.offsetWidth : 0;
            scrollWidth = ref ? ref.scrollWidth : 0;
        }
        return offsetWidth < scrollWidth;
    };
    useEffect(function () {
        var handleOverflowIcon = function () {
            if (isMounted.current) {
                if (isEllipsisActive()) {
                    setShowOverflowIcon(true);
                }
                else {
                    setShowOverflowIcon(false);
                }
            }
        };
        var handleAddEventListener = function () {
            window.addEventListener("resize", function () {
                handleOverflowIcon();
            });
        };
        isMounted.current = true;
        if (props.type === "custom") {
            handleOverflowIcon();
            handleAddEventListener();
        }
        return function () {
            window.removeEventListener("resize", function () {
                handleOverflowIcon();
            });
            isMounted.current = false;
        };
    }, [spanRef, props.type]);
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { ref: spanRef, tabIndex: 0, className: "mpng-ux-logo-text" }, props.type && props.type !== "custom" ? (React.createElement(BrandedText, { registered: props.registered })) : (React.createElement("span", { className: (props.title ? "logo-text-custom" : "") + " " + (props.logoClassName ? "text-primary" : "") + " " + (props.icon ? "with-icon-header" : "") },
            props.title,
            props.registered && React.createElement("span", { className: "text-xxs text-super" }, "\u00AE")))),
        showOverflowIcon && (React.createElement(React.Fragment, null,
            React.createElement("div", __assign({ ref: divRef, className: "overflow-icon" }, eventHelper(function () { return setShowTooltip(!showTooltip); })),
                React.createElement(Icon, { title: props.title, icon: IconEnum.INFO_CIRCLE, size: SizeEnum.SMALL, squared: true, library: IconLibraryEnum.DUOTONE, className: "text-primary text-xxs text-super" })),
            React.createElement(Overlay, { target: divRef.current, show: showTooltip, placement: "bottom", rootClose: true },
                React.createElement(Tooltip, { id: "overflow-text" }, props.title))))));
};
export default LogoText;
