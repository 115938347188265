var AvatarDescriptionEnum;
(function (AvatarDescriptionEnum) {
    // K-5
    AvatarDescriptionEnum["CHUCK"] = "Cartoon image of a smiling, one-eyed, red monster standing up";
    AvatarDescriptionEnum["CLYDE"] = "Cartoon image of a smiling, one-eyed, gold monster standing up";
    AvatarDescriptionEnum["CRABBY"] = "Cartoon image of a smiling, two-eyed, navy monster standing up";
    AvatarDescriptionEnum["DAISY"] = "Cartoon image of a smiling, two-eyed, magenta monster standing up";
    AvatarDescriptionEnum["FUZZY"] = "Cartoon image of a smiling, two-eyed, yellow monster standing up";
    AvatarDescriptionEnum["HANK"] = "Cartoon image of a smiling, one-eyed, yellow monster standing up";
    AvatarDescriptionEnum["HAPPY"] = "Cartoon image of a smiling, three-eyed, lime green monster standing up";
    AvatarDescriptionEnum["JG"] = "Cartoon image of a smiling, three-eyed, purple monster standing up";
    AvatarDescriptionEnum["JOE"] = "Cartoon image of a smiling, one-eyed, gold monster standing up";
    AvatarDescriptionEnum["JOSEPH"] = "Cartoon image of a smiling, one-eyed, red monster standing up";
    AvatarDescriptionEnum["JUNE"] = "Cartoon image of a smiling, three-eyed, turquoise monster standing up";
    AvatarDescriptionEnum["LEGS"] = "Cartoon image of a smiling, two-eyed, magenta monster standing up";
    AvatarDescriptionEnum["LILY"] = "Cartoon image of a smiling, two-eyed, red monster standing up";
    AvatarDescriptionEnum["MEL"] = "Cartoon image of a smiling, three-eyed, purple monster standing up";
    AvatarDescriptionEnum["POPPY"] = "Cartoon image of a smiling, three-eyed, lime green monster standing up";
    AvatarDescriptionEnum["ROCKY"] = "Cartoon image of a smiling, one-eyed, blue monster standing up";
    AvatarDescriptionEnum["ROSE"] = "Cartoon image of a smiling, two-eyed, violet monster standing up";
    AvatarDescriptionEnum["SID"] = "Cartoon image of a smiling, three-eyed, green monster standing up";
    AvatarDescriptionEnum["SPARKLE"] = "Cartoon image of a smiling, three-eyed, turquoise monster standing up";
    AvatarDescriptionEnum["SUNNY"] = "Cartoon image of a smiling, two-eyed, navy monster standing up";
    AvatarDescriptionEnum["TULIP"] = "Cartoon image of a smiling, three-eyed, green monster standing up";
    // 6-8
    AvatarDescriptionEnum["GADGET"] = "Dog with floppy ears";
    AvatarDescriptionEnum["HARRIS"] = "Harris";
    AvatarDescriptionEnum["MEG"] = "Panda bear";
    AvatarDescriptionEnum["MO"] = "White and tan dog";
    AvatarDescriptionEnum["NANO"] = "Koala bear";
    AvatarDescriptionEnum["OTTO"] = "Raccoon with glasses";
    AvatarDescriptionEnum["RED"] = "Bear waving";
    AvatarDescriptionEnum["SKY"] = "White Bunny";
    AvatarDescriptionEnum["SMILEY"] = "Kitten with headphones";
    AvatarDescriptionEnum["SMOKY"] = "Lion with mane";
    AvatarDescriptionEnum["TANGO"] = "Giraffe blowing a bubble";
    AvatarDescriptionEnum["HARRY"] = "Tan labrador retriever dog";
    // 9-12
    AvatarDescriptionEnum["CAT"] = "Branded Avatar";
    AvatarDescriptionEnum["GRIZZLY"] = "Photo of a bear portrait";
    AvatarDescriptionEnum["LION"] = "Photo of a lion head portrait";
    AvatarDescriptionEnum["SHARK"] = "Photo of a shark portrait";
    AvatarDescriptionEnum["MONKEY"] = "Photo of a monkey portrait";
    AvatarDescriptionEnum["HORSE"] = "Photo of a horse head portrait";
    AvatarDescriptionEnum["PANDA"] = "Illustration of a panda bear portrait";
    AvatarDescriptionEnum["ELK"] = "Illustration of a elk portrait";
    AvatarDescriptionEnum["FOX"] = "Illustration of a fox portrait";
    AvatarDescriptionEnum["RACCOON"] = "Illustration of a raccoon portrait";
    AvatarDescriptionEnum["GIRAFFE"] = "Illustration of a giraffe portrait";
})(AvatarDescriptionEnum || (AvatarDescriptionEnum = {}));
export default AvatarDescriptionEnum;
