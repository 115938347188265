var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import RBAccordion from "react-bootstrap/Accordion";
import classMap from "../../shared/utilities/classMap";
import AccordionToggle from "./accordion-toggle";
var Accordion = function (props) {
    var defaultActiveKey = String(typeof props.defaultKey === "number" ? props.defaultKey : -1);
    var _a = React.useState(defaultActiveKey), activeKey = _a[0], setActiveKey = _a[1];
    return (React.createElement(RBAccordion, __assign({}, {
        className: classMap("mpng-ux-accordion", props.className || undefined),
        defaultActiveKey: defaultActiveKey
    }), props.items.map(function (child, index) {
        var eventKey = String(index);
        return (React.createElement("div", { key: index, id: "mpng-ux-accordion-child-" + index, className: classMap("mpng-ux-accordion-child", child.className) },
            React.createElement(AccordionToggle, __assign({}, {
                className: child.toggleClassName,
                eventKey: eventKey,
                activeKey: activeKey,
                body: child === null || child === void 0 ? void 0 : child.title,
                callback: function (newActiveKey) {
                    setActiveKey(activeKey === newActiveKey ? "-1" : newActiveKey);
                    if (typeof props.callback === "function") {
                        props.callback(eventKey);
                    }
                },
                openIndicator: props.openIndicator,
                indicatorSize: props.indicatorSize
            })),
            React.createElement(RBAccordion.Collapse, { eventKey: eventKey },
                React.createElement("div", { className: "collapse-body " + child.bodyClassName }, child.body))));
    })));
};
export default Accordion;
