var ColorsEnum;
(function (ColorsEnum) {
    ColorsEnum["BLUE"] = "blue";
    ColorsEnum["INDIGO"] = "indigo";
    ColorsEnum["PURPLE"] = "purple";
    ColorsEnum["PINK"] = "pink";
    ColorsEnum["RED"] = "red";
    ColorsEnum["ORANGE"] = "orange";
    ColorsEnum["YELLOW"] = "yellow";
    ColorsEnum["GREEN"] = "green";
    ColorsEnum["TEAL"] = "teal";
    ColorsEnum["CYAN"] = "cyan";
})(ColorsEnum || (ColorsEnum = {}));
export default ColorsEnum;
