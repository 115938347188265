var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import classMap from "../../shared/utilities/classMap";
import TableToggle from "./table-toggle";
import "./table.scss";
var Table = function (props) {
    var _a = React.useState("-1"), activeKey = _a[0], setActiveKey = _a[1];
    var colCount = Math.floor(12 / props.headings.length);
    var remainder = 12 - props.headings.length * colCount;
    var getCols = function (columns) {
        return columns.map(function (value, index) {
            var first = Boolean(index === 0);
            var columnClass = props.stretchFirstCol
                ? (first && "col-" + (12 - (props.headings.length - 1) * 2)) || "col-2"
                : "col-" + (colCount + (index < remainder ? 1 : 0));
            return (React.createElement("div", { key: index, className: classMap("column-" + index, columnClass) }, value));
        });
    };
    return (React.createElement(Container, { className: classMap("mpng-ux-table", props.className, "table mw-100 mb-auto p-0", (props.striped && "table-striped") || undefined, (props.bordered && "table-bordered") || undefined, (props.separated && "table-separated") || undefined, (props.hover && "table-hover") || undefined) },
        // duplicate 1st row cap to align the headings with table columns
        props.items && props.items[0] && (React.createElement("div", { className: "float-left", style: { height: 1, visibility: "hidden" } }, props.items[0].rowCap)),
        React.createElement(Row, { className: classMap("table-heading m-0 py-2", props.headingsClassName, !props.headings.length ? "d-none" : "", props.openIndicator ? "pl-3" : "") }, getCols(props.headings)),
        React.createElement(Accordion, { defaultActiveKey: "-1", activeKey: activeKey, as: Container, className: "mw-100 p-0" }, props.items.map(function (child, key) {
            var eventKey = child.itemKey || String(key);
            var clonedProps = __assign({}, child);
            delete clonedProps.body;
            delete clonedProps.callback;
            delete clonedProps.rowCap;
            delete clonedProps.rowCapBody;
            delete clonedProps.itemKey;
            delete clonedProps.className;
            var propValues = Object.values(clonedProps);
            var expandable = Boolean(child.body || child.callback);
            return (React.createElement("div", { className: classMap("table-row row m-0", child.className, eventKey === props.focusedRow ? "focus-class" : ""), key: key },
                React.createElement(Container, { className: classMap("mw-100 d-flex align-items-center px-0") },
                    React.createElement(TableToggle, { activeKey: activeKey, eventKey: eventKey, className: classMap("table-row-toggle py-3 mr-0 w-100", props.openIndicator ? "pl-3" : "ml-0", (expandable && "cursor-pointer")), callback: function (newActiveKey) {
                            setActiveKey(activeKey === newActiveKey ? "-1" : newActiveKey);
                            if (typeof child.callback === "function") {
                                child.callback(eventKey);
                            }
                        }, openIndicator: expandable && props.openIndicator, rowCap: child.rowCap, rowCapBody: child.rowCapBody, rowCapSize: props.rowCapSize }, getCols(propValues))),
                (child === null || child === void 0 ? void 0 : child.body) && (React.createElement(Accordion.Collapse, { eventKey: eventKey, className: "w-100" },
                    React.createElement("div", { className: classMap("container collapse-container mw-100", props.collapseClassName || undefined) }, child.body)))));
        }))));
};
export default Table;
