import React from "react";
import classMap from "../../shared/utilities/classMap";
var Error = function (props) { return (React.createElement("div", { className: classMap("container-fluid", "mpng-ux-error", props.className) },
    React.createElement("div", { className: "row my-5" },
        React.createElement("div", { className: "col-lg-12" },
            React.createElement("h1", { className: "text-center mt-2 text-primary" }, props.primaryMsg),
            props.secondaryMsg && React.createElement("h2", { className: "text-center mt-2 text-secondary" }, props.secondaryMsg)),
        React.createElement("div", { className: "col-lg-12 text-center mt-4" },
            React.createElement("button", { type: "button", className: "btn btn-primary", onClick: function () {
                    if (typeof props.handleClick === "function") {
                        props.handleClick();
                    }
                } }, props.buttonText))))); };
export default Error;
