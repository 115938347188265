import React from "react";
import imgSvg from "../../assets/icons/loader.svg";
function LoadingScreen() {
    var loadingScreeen = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(5, 4, 33, 0.49)",
        color: "#ffffff",
        minHeight: "100vh"
    };
    return (React.createElement("div", { style: loadingScreeen, className: "mpng-ux-loading-screen" },
        React.createElement("div", null,
            React.createElement("img", { src: imgSvg, alt: "", width: "100%", height: "100%" }),
            React.createElement("br", null),
            React.createElement("label", null, "Loading"))));
}
export default LoadingScreen;
