var PriorityEnum;
(function (PriorityEnum) {
    PriorityEnum["PRIMARY"] = "primary";
    PriorityEnum["SECONDARY"] = "secondary";
    PriorityEnum["SUCCESS"] = "success";
    PriorityEnum["DANGER"] = "danger";
    PriorityEnum["WARNING"] = "warning";
    PriorityEnum["INFO"] = "info";
    PriorityEnum["NAVBAR"] = "navbar";
})(PriorityEnum || (PriorityEnum = {}));
export default PriorityEnum;
