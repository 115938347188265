var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import classMap from "../../shared/utilities/classMap";
import Icon from "../icon";
import LogoText from "../logo-text";
var Logo = function (props) { return (React.createElement("span", { className: classMap("mpng-ux-logo", props.className) },
    React.createElement(Icon, __assign({}, props)),
    !props.iconOnly && React.createElement(LogoText, __assign({}, props)))); };
export default Logo;
