import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import config from "../../config";
import { ThemeContext } from "../../providers/themes";
import AvatarEnum from "../../shared/enums/avatar.enum";
import BrandEnum from "../../shared/enums/brand.enum";
import IconEnum from "../../shared/enums/icon.enum";
import SizeEnum from "../../shared/enums/size.enum";
import assetImport from "../../shared/utilities/assetImport";
import classMap from "../../shared/utilities/classMap";
import convertToUpperSnakeCase from "../../shared/utilities/convertToUpperSnakeCase";
import getIconEnumValue from "../../shared/utilities/getIconEnumValue";
import isValidEnum from "../../shared/utilities/isValidEnum";
var Icon = function (props) { return (React.createElement(ThemeContext.Consumer, null, function (theme) {
    // check if Font Awesome
    var isFA = function (icon) {
        var key = convertToUpperSnakeCase(icon);
        return Boolean(IconEnum[key]);
    };
    // extract enum key from pattern (ex: KEY: assets:icons/key.svg)
    var extractEnumKey = function (icon) {
        var match = icon.match(/:[a-z-]+\/([a-z-]+)/);
        return convertToUpperSnakeCase(match && match.length > 1 ? match[1] : icon);
    };
    // get size
    var getSize = function () { return getIconEnumValue(props.size); };
    var isValidIcon = isValidEnum(extractEnumKey(props.icon || ""), IconEnum, BrandEnum, AvatarEnum);
    var iconLibrary = config.iconLibrary[props.library || config.defaults.iconLibrary];
    // add a single pack to library
    library.add(iconLibrary.pack);
    if (!props.icon) {
        return null;
    }
    return isFA(props.icon) ? (React.createElement(FontAwesomeIcon, { icon: [iconLibrary.style, props.icon], className: classMap("mpng-ux-icon", props.className), size: (props.size !== SizeEnum.MEDIUM ? props.size : undefined), title: props.title, style: {
            width: props.squared ? Number(getSize()) : undefined,
            height: props.squared ? Number(getSize()) : undefined
        } })) : (React.createElement("img", { alt: props.alt || props.title, src: isValidIcon ? assetImport(props.icon, theme) : props.icon, width: getSize(), height: props.squared ? getSize() : undefined, className: classMap("mpng-ux-icon d-inline-block", props.className) }));
})); };
export default Icon;
