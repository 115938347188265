var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Button from "react-bootstrap/Button";
import ActionTypeEnum from "../../shared/enums/action-type.enum";
import PriorityEnum from "../../shared/enums/priority.enum";
import SizeEnum from "../../shared/enums/size.enum";
import classMap from "../../shared/utilities/classMap";
import FaIcon from "../fa-icon";
var ButtonIcon = function (props) {
    var _a;
    var styleClass = (props.style && props.style === "rounded" ? "badge-pill" : props.style) || "";
    var content = React.createElement(FaIcon, __assign({}, __assign(__assign({}, props.icon), { title: props.text })));
    var clickHandler = function (event) {
        event.stopPropagation();
        if (props.callback)
            props.callback();
    };
    return (React.createElement(Button, { "aria-label": props.text || props.ariaLabel || "button", variant: props.priority || PriorityEnum.PRIMARY, size: props.size && props.size !== SizeEnum.MEDIUM ? props.size : SizeEnum.LARGE, disabled: props.enabled !== undefined ? !props.enabled : false, onClick: clickHandler, type: props.type || ActionTypeEnum.BUTTON, tabIndex: (_a = props.tabIndex) !== null && _a !== void 0 ? _a : undefined, className: classMap("mpng-ux-buttonicon shape", styleClass, props.className) }, content));
};
export default ButtonIcon;
