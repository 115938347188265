var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import IconEnum from "../../shared/enums/icon.enum";
import SizeEnum from "../../shared/enums/size.enum";
import StateEnum from "../../shared/enums/state.enum";
import classMap from "../../shared/utilities/classMap";
import eventHelper from "../../shared/utilities/eventHelper";
import Accordion from "../accordion";
import Indicator from "../indicator";
import "./timeline.scss";
var Timeline = function (props) {
    var _a = React.useState(props.defaultKey), activeKey = _a[0], setActiveKey = _a[1];
    var stateClasses = {
        complete: {
            className: "complete"
        },
        interactive: {
            className: "interactive"
        },
        locked: {
            className: "locked"
        }
    };
    return (React.createElement(Accordion, __assign({}, __assign(__assign({}, props), { className: classMap("mpng-ux-timeline", props.className), items: props.items.map(function (child, index) {
            var _a, _b;
            var classes = stateClasses[(_a = child.state) !== null && _a !== void 0 ? _a : StateEnum.LOCKED];
            return __assign(__assign({}, child), { title: (React.createElement("div", { className: classMap("timeline-title mt-3 d-flex align-items-center", activeKey === index ? "selected" : "", child.state) },
                    React.createElement("div", { id: "timeline-marker-node-" + index, className: classMap("timeline-marker", "rounded-circle", "d-flex align-items-center", "font-weight-bold", "mr-3 z-index-2", activeKey === index ? "text-xl" : "text-lg ml-1", classes.className) },
                        React.createElement("span", { className: "mx-auto" }, (_b = child.label) !== null && _b !== void 0 ? _b : index + 1)),
                    props.openIndicator && (React.createElement("div", { className: "pl-2 timeline-open-indicator" },
                        React.createElement(Indicator, { icon: activeKey === index ? IconEnum.CHEVRON_DOWN : IconEnum.CHEVRON_RIGHT, size: SizeEnum.SMALL, className: "d-inline-flex mr-1" }))),
                    child.title)), body: (React.createElement(React.Fragment, null,
                    child.body && React.createElement("div", { className: "p-3" }, child.body),
                    React.createElement("div", { className: "pt-3 px-3" }, child.items &&
                        child.items.map(function (grandchild, index) { return (React.createElement("div", __assign({}, eventHelper(grandchild.callback), { className: classMap("timeline-child d-flex align-items-center py-2", grandchild.callback ? "cursor-pointer" : "", props.focusedChildItem === grandchild.itemKey ? "focus-class" : "", grandchild.state), tabIndex: grandchild.callback ? 0 : undefined, key: index }),
                            React.createElement("div", { className: classMap("timeline-marker", "rounded-circle", "d-flex align-items-center", "position-absolute z-index-2", grandchild.state === StateEnum.LOCKED ? "locked" : "", grandchild.state === StateEnum.INTERACTIVE ? "interactive" : "") }),
                            React.createElement("div", { className: "timeline-grandchild truncate-lines lines-2 px-3", title: grandchild.title }, grandchild.title))); })))), bodyClassName: classMap("timeline-body", child.bodyClassName) });
        }), callback: function (key) {
            setActiveKey(key !== activeKey ? Number(key) : -1);
            if (key !== activeKey && props.callback) {
                props.callback(key);
            }
        }, defaultKey: props.defaultKey }))));
};
export default Timeline;
