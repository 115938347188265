var AvatarEnum;
(function (AvatarEnum) {
    // K-5
    AvatarEnum["CHUCK"] = "svg:Chuck";
    AvatarEnum["CLYDE"] = "svg:Clyde";
    AvatarEnum["CRABBY"] = "svg:Crabby";
    AvatarEnum["DAISY"] = "svg:Daisy";
    AvatarEnum["FUZZY"] = "svg:Fuzzy";
    AvatarEnum["HANK"] = "svg:Hank";
    AvatarEnum["HAPPY"] = "svg:Happy";
    AvatarEnum["JG"] = "svg:JG";
    AvatarEnum["JOE"] = "svg:Joe";
    AvatarEnum["JOSEPH"] = "svg:Joseph";
    AvatarEnum["JUNE"] = "svg:June";
    AvatarEnum["LEGS"] = "svg:Legs";
    AvatarEnum["LILY"] = "svg:Lily";
    AvatarEnum["MEL"] = "svg:Mel";
    AvatarEnum["POPPY"] = "svg:Poppy";
    AvatarEnum["ROCKY"] = "svg:Rocky";
    AvatarEnum["ROSE"] = "svg:Rose";
    AvatarEnum["SID"] = "svg:Sid";
    AvatarEnum["SPARKLE"] = "svg:Sparkle";
    AvatarEnum["SUNNY"] = "svg:Sunny";
    AvatarEnum["TULIP"] = "svg:Tulip";
    // 6-8
    AvatarEnum["GADGET"] = "png:Gadget";
    AvatarEnum["HARRIS"] = "svg:Harris";
    AvatarEnum["MEG"] = "png:Meg";
    AvatarEnum["MO"] = "png:Mo";
    AvatarEnum["NANO"] = "png:Nano";
    AvatarEnum["OTTO"] = "png:Otto";
    AvatarEnum["RED"] = "png:Red";
    AvatarEnum["SKY"] = "png:Sky";
    AvatarEnum["SMILEY"] = "png:Smiley";
    AvatarEnum["SMOKY"] = "png:Smoky";
    AvatarEnum["TANGO"] = "png:Tango";
    AvatarEnum["HARRY"] = "png:Harry";
    // 9-12
    AvatarEnum["CAT"] = "png:Cat";
    AvatarEnum["GRIZZLY"] = "png:Grizzly";
    AvatarEnum["LION"] = "png:Lion";
    AvatarEnum["SHARK"] = "png:Shark";
    AvatarEnum["MONKEY"] = "png:Monkey";
    AvatarEnum["HORSE"] = "png:Horse";
    AvatarEnum["PANDA"] = "png:Panda";
    AvatarEnum["ELK"] = "png:Elk";
    AvatarEnum["FOX"] = "png:Fox";
    AvatarEnum["RACCOON"] = "png:Raccoon";
    AvatarEnum["GIRAFFE"] = "png:Giraffe";
})(AvatarEnum || (AvatarEnum = {}));
export default AvatarEnum;
