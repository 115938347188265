import IconEnum from "../../shared/enums/icon.enum";
import PriorityEnum from "../../shared/enums/priority.enum";
import StyleEnum from "../../shared/enums/style.enum";
var VisualActionDefaults = {
    educator: {
        style: StyleEnum.ROUNDED
    },
    student_k_2: {
        size: "1:1",
        style: StyleEnum.CIRCLE,
        priority: PriorityEnum.PRIMARY,
        content: {
            className: "align-items-center text-center text-white",
            action: {
                priority: PriorityEnum.SUCCESS,
                style: StyleEnum.ROUNDED,
                text: "GO",
                className: "text-white mx-auto",
                icon: {
                    icon: IconEnum.ARROW_RIGHT,
                    className: "ml-1"
                }
            }
        }
    },
    student_3_5: {
        size: "1:1",
        style: StyleEnum.CIRCLE,
        priority: PriorityEnum.PRIMARY,
        content: {
            className: "align-items-center text-center text-white",
            action: {
                priority: PriorityEnum.SUCCESS,
                style: StyleEnum.ROUNDED,
                text: "GO",
                className: "text-white mx-auto",
                icon: {
                    icon: IconEnum.ARROW_RIGHT,
                    className: "ml-1"
                }
            }
        }
    },
    student_6_8: {
        style: StyleEnum.ROUNDED,
        priority: PriorityEnum.PRIMARY,
        className: "align-items-center text-center",
        content: {
            action: {
                priority: "link",
                text: "Let's Go!",
                icon: undefined,
                className: "text-white text-decoration-none"
            }
        }
    },
    student_9_12: {
        style: StyleEnum.ROUNDED,
        priority: undefined,
        bodyClassName: "p-0",
        content: {
            action: {
                priority: PriorityEnum.PRIMARY,
                text: "Let's Go!",
                icon: undefined,
                className: "mb-3"
            }
        }
    }
};
export default VisualActionDefaults;
