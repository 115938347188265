var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import RBToast from "react-bootstrap/Toast";
import IconLibraryEnum from "../../shared/enums/icon-library.enum";
import IconEnum from "../../shared/enums/icon.enum";
import SizeEnum from "../../shared/enums/size.enum";
import classMap from "../../shared/utilities/classMap";
import Action from "../action";
import Icon from "../icon";
var Toast = function (props) {
    var _a, _b;
    var _c = React.useState(true), showToast = _c[0], setShowToast = _c[1];
    var handleClose = function (close) {
        if (props.handleClose) {
            return props.handleClose();
        }
        return setShowToast(close);
    };
    return (React.createElement(RBToast, __assign({}, {
        show: props.show || showToast,
        delay: (_a = props.delay) !== null && _a !== void 0 ? _a : undefined,
        autohide: (_b = Boolean(props.delay)) !== null && _b !== void 0 ? _b : undefined,
        onClose: function () { return handleClose(false); },
        className: classMap("mpng-ux-toast", props.className, props.dark ? "bg-dark text-light" : "")
    }),
        React.createElement("div", { className: "p-3 d-flex align-items-center" },
            props.headerIcon && (React.createElement("div", null,
                React.createElement(Icon, { icon: props.headerIcon, library: IconLibraryEnum.REGULAR, size: SizeEnum.SMALL, squared: true, className: "d-inline-flex mr-2" }))),
            props.header && (React.createElement("div", { className: classMap("toast-header", props.headerClassName, props.dismissible ? "mr-auto" : "") }, props.header)),
            props.dismissible && (React.createElement("div", null,
                React.createElement(Action, __assign({}, {
                    callback: function () { return handleClose(false); },
                    className: classMap("p-0", props.dark ? "text-light" : "text-dark"),
                    icon: {
                        icon: IconEnum.TIMES,
                        library: IconLibraryEnum.LIGHT,
                        size: SizeEnum.SMALL,
                        squared: true,
                        className: "d-inline-flex"
                    },
                    priority: "link",
                    tabIndex: 0
                }))))),
        props.body && React.createElement("div", { className: props.hideBorders ? "p-3" : "border-top p-3" }, props.body)));
};
export default Toast;
