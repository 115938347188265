var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import PositionEnum from "../../shared/enums/position.enum";
import classMap from "../../shared/utilities/classMap";
import Icon from "../icon";
import "./enhanced-icon.scss";
var EnhancedIcon = function (props) {
    var textClass = "";
    if (props.title) {
        if (props.textPosition === PositionEnum.LEFT) {
            textClass = "left";
        }
        else if (props.textPosition === PositionEnum.RIGHT) {
            textClass = "right";
        }
    }
    var icon = props.alt === undefined ? (React.createElement("div", { className: classMap("enhanced-icon-icon", props.enhancedClassName) },
        React.createElement(Icon, __assign({}, props, { title: props.title })))) : (React.createElement("div", { className: classMap("enhanced-icon-icon", props.enhancedClassName) },
        React.createElement(Icon, __assign({}, props, { title: props.title, alt: props.alt }))));
    var text = (React.createElement("div", { className: classMap("enhanced-icon-text", props.textClassName, textClass) },
        React.createElement("span", null, props.title)));
    var position = props.textPosition ? props.textPosition : PositionEnum.LEFT;
    var before = position === PositionEnum.TOP || position === PositionEnum.LEFT;
    var enhancedIcon = before ? (React.createElement(React.Fragment, null,
        text,
        icon)) : (React.createElement(React.Fragment, null,
        icon,
        text));
    var vertical = position === PositionEnum.TOP || position === PositionEnum.BOTTOM;
    var classes = vertical ? "flex-column" : "align-items-center";
    return (React.createElement("div", { className: classMap("mpng-ux-enhanced-icon d-flex", props.enhancedIconClassName, classes) }, enhancedIcon));
};
export default EnhancedIcon;
