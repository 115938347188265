import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import ThemesEnum from "./shared/enums/themes.enum";
import IconLibraryEnum from "./shared/enums/icon-library.enum";
var config = {
    defaults: {
        theme: ThemesEnum.STUDENT_3_5,
        themePerGradeMap: [
            ThemesEnum.STUDENT_K_2,
            ThemesEnum.STUDENT_K_2,
            ThemesEnum.STUDENT_K_2,
            ThemesEnum.STUDENT_3_5,
            ThemesEnum.STUDENT_3_5,
            ThemesEnum.STUDENT_3_5,
            ThemesEnum.STUDENT_6_8,
            ThemesEnum.STUDENT_6_8,
            ThemesEnum.STUDENT_6_8,
            ThemesEnum.STUDENT_9_12,
            ThemesEnum.STUDENT_9_12,
            ThemesEnum.STUDENT_9_12,
            ThemesEnum.STUDENT_9_12
        ],
        breakpoints: {
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200
        },
        // avatar base path
        basePaths: {
            avatars: "assets:avatars/"
        },
        // icon library
        iconLibrary: IconLibraryEnum.SOLID
    },
    // Font Awesome library types
    iconLibrary: {
        regular: {
            style: "far",
            pack: far
        },
        light: {
            style: "fal",
            pack: fal
        },
        duotone: {
            style: "fad",
            pack: fad
        },
        solid: {
            style: "fas",
            pack: fas
        }
    }
};
export default config;
