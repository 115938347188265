var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import Row from "react-bootstrap/Row";
import Icon from "../icon";
import IconEnum from "../../shared/enums/icon.enum";
import classMap from "../../shared/utilities/classMap";
import eventHelper from "../../shared/utilities/eventHelper";
var TableToggle = function (props) {
    var _a = React.useState(false), rowCapOpen = _a[0], setRowCapOpen = _a[1];
    var toggleAccordion = useAccordionToggle(props.eventKey, function () {
        if (typeof props.callback === "function") {
            props.callback(props.eventKey);
        }
    });
    return (React.createElement(React.Fragment, null,
        props.rowCap &&
            (props.rowCapBody ? (React.createElement("div", __assign({ className: "h-100 cursor-pointer" }, eventHelper(setRowCapOpen(!rowCapOpen))),
                React.createElement("div", { className: classMap("position-absolute animation width appear bg-dark text-light overflow-hidden text-nowrap text-center", rowCapOpen ? "size" : "closed", (props.rowCapSize || "md")), style: { zIndex: 1 } },
                    React.createElement("div", { className: "p-3" }, props.rowCapBody)),
                props.rowCap)) : (props.rowCap)),
        props.openIndicator && (React.createElement("div", __assign({ style: { width: 15, zIndex: 1 }, className: "pl-2 cursor-pointer" }, eventHelper(toggleAccordion), { tabIndex: undefined }),
            React.createElement(Icon, { icon: props.activeKey === props.eventKey ? IconEnum.CHEVRON_DOWN : IconEnum.CHEVRON_RIGHT, className: "mr-3" }))),
        React.createElement(Row, __assign({ id: "table-row-toggle-" + props.eventKey }, eventHelper(toggleAccordion), { className: props.className }), props.children)));
};
export default TableToggle;
