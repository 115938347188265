var BackgroundEnum;
(function (BackgroundEnum) {
    // Default initial assets
    BackgroundEnum["LOGIN"] = "assets:backgrounds/login.png";
    BackgroundEnum["OCEAN"] = "assets:backgrounds/ocean.svg";
    BackgroundEnum["WAVES"] = "assets:backgrounds/waves.svg";
    BackgroundEnum["PURPLE_PINWHEEL"] = "assets:backgrounds/purple-pinwheel.svg";
    // K-5
    BackgroundEnum["BLUE_SEA"] = "assets:backgrounds/blue-sea.svg";
    BackgroundEnum["BLUE_SKY"] = "assets:backgrounds/blue-sky.svg";
    BackgroundEnum["DARK_GALAXY"] = "assets:backgrounds/dark-galaxy.svg";
    BackgroundEnum["DEEP_DIVE"] = "assets:backgrounds/deep-dive.svg";
    BackgroundEnum["DESERT"] = "assets:backgrounds/desert.svg";
    BackgroundEnum["FOREST"] = "assets:backgrounds/forest.svg";
    BackgroundEnum["HAPPY_HILLS"] = "assets:backgrounds/happy-hills.svg";
    BackgroundEnum["SUNSET"] = "assets:backgrounds/sunset.svg";
    BackgroundEnum["SUNSET_HILLS"] = "assets:backgrounds/sunset-hills.svg";
    BackgroundEnum["SUNSPOT_GALAXY"] = "assets:backgrounds/sunspot-galaxy.svg";
    BackgroundEnum["SUNNY_GALAXY"] = "assets:backgrounds/sunny-galaxy.svg";
    // 6-8
    BackgroundEnum["MOUNTAIN_FALLS"] = "assets:backgrounds/mountain-falls.jpg";
    BackgroundEnum["COSMOS"] = "assets:backgrounds/cosmos.png";
    BackgroundEnum["WAVE"] = "assets:backgrounds/wave.png";
    BackgroundEnum["CACTI"] = "assets:backgrounds/cacti.png";
    BackgroundEnum["WATER"] = "assets:backgrounds/water.png";
    BackgroundEnum["JELLYFISH"] = "assets:backgrounds/jellyfish.png";
    BackgroundEnum["PINK_PATTERN"] = "assets:backgrounds/pink-pattern.png";
    BackgroundEnum["DOG"] = "assets:backgrounds/dog.png";
    BackgroundEnum["PANDA"] = "assets:backgrounds/panda.png";
    BackgroundEnum["COOL_CAT"] = "assets:backgrounds/cool-cat.png";
    // 9-12
    BackgroundEnum["PURPLE_PATTERN"] = "assets:backgrounds/purple-pattern.svg";
    BackgroundEnum["BIG_WAVE"] = "assets:backgrounds/big-wave.jpg";
    BackgroundEnum["BRIGHT_SWATCHES"] = "assets:backgrounds/bright-swatches.jpg";
    BackgroundEnum["DEEP_SPACE"] = "assets:backgrounds/deep-space.jpg";
    BackgroundEnum["HAPPY_PUPPY"] = "assets:backgrounds/happy-puppy.jpg";
    BackgroundEnum["LEAVES"] = "assets:backgrounds/leaves.jpg";
    BackgroundEnum["NAP_TIME"] = "assets:backgrounds/nap-time.jpg";
    BackgroundEnum["SPACE_LIGHT"] = "assets:backgrounds/space-light.jpg";
    BackgroundEnum["SUNNY_STREAM"] = "assets:backgrounds/sunny-stream.jpg";
    BackgroundEnum["WATERFALL"] = "assets:backgrounds/waterfall.jpg";
    BackgroundEnum["MOUNTAINS"] = "assets:backgrounds/mountains.jpg";
    // Visual Action Backgrounds
    BackgroundEnum["ENGLISH"] = "assets:backgrounds/reading.png";
    BackgroundEnum["READING"] = "assets:backgrounds/reading.png";
    BackgroundEnum["MATH"] = "assets:backgrounds/math.png";
    // Theme specific
    BackgroundEnum["DEFAULT"] = "theme:backgrounds/default.svg";
    BackgroundEnum["PRIMARY"] = "theme:backgrounds/primary.svg";
})(BackgroundEnum || (BackgroundEnum = {}));
export default BackgroundEnum;
