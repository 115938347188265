var IconEnum;
(function (IconEnum) {
    // Assets
    IconEnum["MATH"] = "theme:icons/math.svg";
    IconEnum["READING"] = "theme:icons/reading.svg";
    IconEnum["ENGLISH"] = "theme:icons/reading.svg";
    IconEnum["SEL"] = "theme:icons/sel.svg";
    IconEnum["STAR"] = "assets:icons/star.svg";
    IconEnum["LOGO_BUG"] = "assets:icons/logo-bug.svg";
    IconEnum["TEXT_TO_SPEECH"] = "assets:icons/text-to-speech.svg";
    IconEnum["LANGUAGE_TRANSLATION"] = "assets:icons/language-translation.svg";
    IconEnum["MY_PATH_LOGO"] = "assets:icons/my-path-logo.svg";
    IconEnum["GUIDED_NOTES"] = "assets:icons/guided-notes.svg";
    IconEnum["COLLECT_HIGHLIGHTS"] = "assets:icons/collect-highlights.svg";
    // Font Awesome
    IconEnum["ARROW_ALT_DOWN"] = "arrow-alt-down";
    IconEnum["ARROW_LEFT"] = "arrow-left";
    IconEnum["ARROW_RIGHT"] = "arrow-right";
    IconEnum["ARROW_CIRCLE_LEFT"] = "arrow-circle-left";
    IconEnum["ARROW_CIRCLE_RIGHT"] = "arrow-circle-right";
    IconEnum["ARROW_SQUARE_LEFT"] = "arrow-square-left";
    IconEnum["ARROW_SQUARE_RIGHT"] = "arrow-square-right";
    IconEnum["BARS"] = "bars";
    IconEnum["BOOK"] = "book";
    IconEnum["BOOKS"] = "books";
    IconEnum["BOOK_OPEN"] = "book-open";
    IconEnum["BOOK_READER"] = "book-reader";
    IconEnum["CALCULATOR_ALT"] = "calculator-alt";
    IconEnum["CHEVRON_DOWN"] = "chevron-down";
    IconEnum["CHEVRON_UP"] = "chevron-up";
    IconEnum["CHEVRON_LEFT"] = "chevron-left";
    IconEnum["CHEVRON_RIGHT"] = "chevron-right";
    IconEnum["CHECK"] = "check";
    IconEnum["CHECK_CIRCLE"] = "check-circle";
    IconEnum["CHECK_SQUARE"] = "check-square";
    IconEnum["CIRCLE"] = "circle";
    IconEnum["CIRCLE_NOTCH"] = "circle-notch";
    IconEnum["DOT_CIRCLE"] = "dot-circle";
    IconEnum["ELLIPSIS_V"] = "ellipsis-v";
    IconEnum["ERASER"] = "eraser";
    IconEnum["GREATER_THAN"] = "greater-than";
    IconEnum["HIGHLIGHTER"] = "highlighter";
    IconEnum["HISTORY"] = "history";
    IconEnum["ID_BADGE"] = "id-badge";
    IconEnum["IMAGES"] = "images";
    IconEnum["INFO_CIRCLE"] = "info-circle";
    IconEnum["LESS_THAN"] = "less-than";
    IconEnum["LIGHTBULB"] = "lightbulb";
    IconEnum["LIGHTBULB_ON"] = "lightbulb-on";
    IconEnum["LOCK"] = "lock";
    IconEnum["LOCK_ALT"] = "lock-alt";
    IconEnum["MAP_MARKER_ALT"] = "map-marker-alt";
    IconEnum["MINUS"] = "minus";
    IconEnum["MINUS_CIRCLE"] = "minus-circle";
    IconEnum["MINUS_SQUARE"] = "minus-square";
    IconEnum["PAUSE"] = "pause";
    IconEnum["PLAY"] = "play";
    IconEnum["PLUS"] = "plus";
    IconEnum["PLUS_CIRCLE"] = "plus-circle";
    IconEnum["PLUS_SQUARE"] = "plus-square";
    IconEnum["POWER_OFF"] = "power-off";
    IconEnum["QUESTION_CIRCLE"] = "question-circle";
    IconEnum["STOP"] = "stop";
    IconEnum["SQUARE"] = "square";
    IconEnum["TH"] = "th";
    IconEnum["TH_LIST"] = "th-list";
    IconEnum["TIMES"] = "times";
    IconEnum["TIMES_CIRCLE"] = "times-circle";
    IconEnum["USER"] = "user";
    IconEnum["USER_CIRCLE"] = "user-circle";
    IconEnum["USER_EDIT"] = "user-edit";
    IconEnum["USER_HEADSET"] = "user-headset";
    IconEnum["USERS"] = "users";
    IconEnum["VOLUME"] = "volume";
    IconEnum["WRENCH"] = "wrench";
})(IconEnum || (IconEnum = {}));
export default IconEnum;
