import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import config from "../../config";
import SizeEnum from "../../shared/enums/size.enum";
import classMap from "../../shared/utilities/classMap";
import getIconEnumValue from "../../shared/utilities/getIconEnumValue";
var FaIcon = function (props) {
    // get size
    var getSize = function () { return getIconEnumValue(props.size); };
    var iconLibrary = config.iconLibrary[props.library || config.defaults.iconLibrary];
    // add a single pack to library
    library.add(iconLibrary.pack);
    if (!props.icon) {
        return null;
    }
    return (React.createElement(FontAwesomeIcon, { icon: [iconLibrary.style, props.icon], className: classMap("mpng-ux-faicon", props.className), size: (props.size !== SizeEnum.MEDIUM ? props.size : undefined), title: props.title, style: {
            width: props.squared ? Number(getSize()) : undefined,
            height: props.squared ? Number(getSize()) : undefined
        } }));
};
export default FaIcon;
