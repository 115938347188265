var IconSizeEnum;
(function (IconSizeEnum) {
    IconSizeEnum[IconSizeEnum["SM"] = 12] = "SM";
    IconSizeEnum[IconSizeEnum["MD"] = 20] = "MD";
    IconSizeEnum[IconSizeEnum["LG"] = 30] = "LG";
    IconSizeEnum[IconSizeEnum["X1"] = 40] = "X1";
    IconSizeEnum[IconSizeEnum["X2"] = 60] = "X2";
    IconSizeEnum[IconSizeEnum["X3"] = 90] = "X3";
    IconSizeEnum[IconSizeEnum["X4"] = 150] = "X4";
    IconSizeEnum[IconSizeEnum["X5"] = 200] = "X5";
})(IconSizeEnum || (IconSizeEnum = {}));
export default IconSizeEnum;
