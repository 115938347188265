/**
 * @function classMap
 * @param {string[]} classes The string array of classes
 * @description Combines classes into a browser readable string
 * @returns {string} Browser ready string of classes
 */
var classMap = function () {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return classes
        .join(" ")
        .split(" ")
        .reduce(function (previousValue, currentValue) {
        if (currentValue && previousValue.split(" ").indexOf(currentValue) < 0) {
            return previousValue + " " + currentValue;
        }
        return previousValue;
    });
};
export default classMap;
