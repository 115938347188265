import IconSizeEnum from "../enums/icon-size.enum";
import SizeExtendedEnum from "../enums/size-extended.enum";
/**
 * @function getIconEnumValue
 * @param {SizeEnum | SizeExtendedEnum | undefined} enumObject enum to get the value from
 * @description Returns icon enum value
 * @returns {string} any
 */
var getIconEnumValue = function (enumObject) {
    var value = String(enumObject || SizeExtendedEnum.X1);
    var chars = value.split("");
    if (parseInt(chars[0], 10)) {
        value = "" + chars[1] + chars[0];
    }
    return IconSizeEnum[value.toUpperCase()];
};
export default getIconEnumValue;
