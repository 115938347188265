var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * @function eventHelper
 * @param {function} callback the callback to help with
 * @param {function} customProps additional properties to add and overwrite
 * @description Creates a handler for mouse click and keyboad enter or space
 * @returns {object} the handlers in prop object form
 */
var eventHelper = function (callback, customProps) {
    if (callback === void 0) { callback = function () { }; }
    return (__assign({ onClick: callback, onKeyPress: function (event) {
            if (event.key === "Enter" || event.key === " ") {
                callback(event);
            }
        }, tabIndex: 0 }, customProps));
};
export default eventHelper;
