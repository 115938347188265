var PreloaderTypeEnum;
(function (PreloaderTypeEnum) {
    PreloaderTypeEnum["AUDIO"] = "Audio";
    PreloaderTypeEnum["BALL_TRIANGLE"] = "BallTriangle";
    PreloaderTypeEnum["BARS"] = "Bars";
    PreloaderTypeEnum["CIRCLES"] = "Circles";
    PreloaderTypeEnum["GRID"] = "Grid";
    PreloaderTypeEnum["HEARTS"] = "Hearts";
    PreloaderTypeEnum["OVAL"] = "Oval";
    PreloaderTypeEnum["PUFF"] = "Puff";
    PreloaderTypeEnum["RINGS"] = "Rings";
    PreloaderTypeEnum["SINNING_CIRCLES"] = "SpinningCircles";
    PreloaderTypeEnum["TAIL_SPIN"] = "TailSpin";
    PreloaderTypeEnum["THREE_DOTS"] = "ThreeDots";
})(PreloaderTypeEnum || (PreloaderTypeEnum = {}));
export default PreloaderTypeEnum;
