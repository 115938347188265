var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import config from "../../config";
import AvatarDescriptionEnum from "../../shared/enums/avatar-description.enum";
import AvatarPriorityEnum from "../../shared/enums/avatar-priority.enum";
import AvatarStyleEnum from "../../shared/enums/avatar-style.enum";
import PositionEnum from "../../shared/enums/position.enum";
import classMap from "../../shared/utilities/classMap";
import getIconEnumValue from "../../shared/utilities/getIconEnumValue";
import EnhancedIcon from "../enhanced-icon";
import "./avatar.scss";
var Avatar = function (props) {
    var _a, _b;
    var base = config.defaults.basePaths.avatars;
    var avatarStyle = (_a = props.avatarStyle) !== null && _a !== void 0 ? _a : AvatarStyleEnum.BODY;
    var headOnly = avatarStyle === AvatarStyleEnum.HEAD;
    var avatarType = (_b = props.avatarType) !== null && _b !== void 0 ? _b : AvatarPriorityEnum.DEFAULT;
    var avatarSplit = props.avatar.split(":");
    var fileType = avatarSplit[0];
    var avatar = avatarSplit[1].toLowerCase();
    return (React.createElement("div", { className: classMap("mpng-ux-avatar", "overflow-hidden d-inline-block", props.className), style: {
            transform: "rotate(" + props.rotation + "deg)",
            height: headOnly ? getIconEnumValue(props.size) / 2 : undefined
        } },
        React.createElement(EnhancedIcon, __assign({}, __assign(__assign({}, props), { icon: "" + base + avatar + "/" + avatarType + "." + fileType, title: props.showTitle ? props.avatar : undefined, alt: AvatarDescriptionEnum[avatar.toUpperCase()], textPosition: PositionEnum.BOTTOM, squared: true })))));
};
export default Avatar;
