var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import RBModal from "react-bootstrap/Modal";
import PositionEnum from "../../shared/enums/position.enum";
import SizeExtraEnum from "../../shared/enums/size-extra.enum";
import classMap from "../../shared/utilities/classMap";
import "./modal.scss";
var Modal = function (props) {
    var _a, _b;
    var fullscreen = props.size === SizeExtraEnum.FULL;
    var showClose = (_a = props.showClose) !== null && _a !== void 0 ? _a : true;
    var hideBorders = props.hideBorders ? "border-0" : "";
    var idModal = props.id || "mpng-ux-modal-default-" + Date.now();
    var idTitle = idModal + "-title";
    return (React.createElement(RBModal, __assign({}, {
        id: idModal,
        className: classMap("mpng-ux-modal ", props.className),
        show: props.showModal,
        onHide: props.handleClose,
        backdrop: fullscreen ? false : (_b = props.backdrop) !== null && _b !== void 0 ? _b : true,
        size: props.size !== "full" && props.size !== "md" ? props.size : undefined,
        centered: props.vPosition === PositionEnum.CENTER,
        dialogClassName: classMap(fullscreen ? "mw-100 mh-100 h-100 m-0" : undefined, props.hideBorders ? "hide-borders" : "", props.hideContainer ? "hide-container" : ""),
        animation: props.animation || true,
        scrollable: props.scrollable || false,
        backdropClassName: props.backdropClassName,
        contentClassName: classMap("mpng-ux-modal-content ", props.contentClassName)
    }, { "aria-labelledby": idTitle }),
        (props.title || showClose) && (React.createElement(RBModal.Header, { className: classMap(props.headerClassName, hideBorders), closeButton: showClose },
            React.createElement(RBModal.Title, { className: "w-100", id: idTitle }, props.title))),
        React.createElement("div", { role: "main" },
            React.createElement(RBModal.Body, { className: classMap("h-100 mh-100", props.bodyClassName) }, props.body)),
        props.footer && (React.createElement(RBModal.Footer, { className: classMap(hideBorders, props.footerClassName) }, props.footer.map(function (child, key) { return (React.createElement("div", { key: key }, child)); })))));
};
export default Modal;
