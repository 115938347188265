var ElementTypeEnum;
(function (ElementTypeEnum) {
    ElementTypeEnum["DIV"] = "div";
    ElementTypeEnum["P"] = "p";
    ElementTypeEnum["NAV"] = "nav";
    ElementTypeEnum["H1"] = "h1";
    ElementTypeEnum["H2"] = "h2";
    ElementTypeEnum["H3"] = "h3";
    ElementTypeEnum["H4"] = "h4";
    ElementTypeEnum["H5"] = "h5";
})(ElementTypeEnum || (ElementTypeEnum = {}));
export default ElementTypeEnum;
