/**
 * @function isValidEnum
 * @param {string | number} key param to check if valid enum entry
 * @param {any[]} enums enum or list of enums
 * @description Checks a value if a valid enum entry
 * @returns {boolean}
 */
var isValidEnum = function (key) {
    var enums = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        enums[_i - 1] = arguments[_i];
    }
    var isMatched = false;
    enums.every(function (enumType) {
        if (enumType[key]) {
            isMatched = true;
            return false;
        }
        return true;
    });
    return isMatched;
};
export default isValidEnum;
