var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Nav from "react-bootstrap/Nav";
import RBNavbar from "react-bootstrap/Navbar";
import DirectionEnum from "../../shared/enums/direction.enum";
import OrientationEnum from "../../shared/enums/orientation.enum";
import PositionEnum from "../../shared/enums/position.enum";
import ShadesEnum from "../../shared/enums/shades.enum";
import SizeEnum from "../../shared/enums/size.enum";
import classMap from "../../shared/utilities/classMap";
import Logo from "../logo";
import "./navbar.scss";
var Navbar = function (props) {
    var _a;
    var brand = props.brand, slogan = props.slogan, items = props.items, color = props.color, expand = props.expand, orientation = props.orientation, variant = props.variant, fixed = props.fixed, addColumnClass = props.addColumnClass, sloganClassName = props.sloganClassName, bgClassName = props.bgClassName, _b = props.collapseId, collapseId = _b === void 0 ? "basic-navbar-nav-toggle" : _b;
    var sidebar = orientation === OrientationEnum.VERTICAL;
    var footer = orientation === OrientationEnum.HORIZONTAL && fixed === PositionEnum.BOTTOM;
    var collapsible = Boolean(!footer && items.length > 2);
    var childrenByDirection = {
        beginning: [],
        center: [],
        end: []
    };
    items.map(function (childProps) {
        return childrenByDirection[childProps.direction || DirectionEnum.END].push(childProps);
    });
    var content = items.length &&
        Object.values(DirectionEnum).map(function (direction, directionIndex) { return (React.createElement(Nav, { key: directionIndex, className: classMap("align-items-center", addColumnClass ? "col" : "", "justify-content-" + direction, sidebar ? "flex-column" : "", !collapsible ? "flex-row" : "") }, childrenByDirection[direction].map(function (childProps, index) { return (React.createElement("div", { key: index }, childProps.item)); }))); });
    var positionClass = "header";
    if (sidebar) {
        positionClass = "sidebar flex-column";
    }
    else if (footer) {
        positionClass = "footer";
    }
    var opacityNavBar = (React.createElement("div", { className: classMap("navbar-bg position-absolute w-100 h-100 left-0 z-index--1", "bg-" + color, bgClassName) }));
    return (React.createElement(RBNavbar, __assign({}, {
        expand: sidebar ? undefined : expand !== null && expand !== void 0 ? expand : SizeEnum.MEDIUM,
        // bg: color
        variant: variant || ShadesEnum.LIGHT,
        className: classMap("mpng-ux-navbar", "flex-nowrap", positionClass, props.className),
        fixed: props.fixed,
        sticky: props.sticky,
        role: (_a = props.role) !== null && _a !== void 0 ? _a : "navigation",
        as: props.asDiv ? "div" : undefined
    }),
        opacityNavBar,
        brand && (React.createElement(RBNavbar.Brand, { href: brand.href || undefined, className: classMap(sidebar ? "m-0" : "text-truncate", props.brandCallback && "cursor-pointer"), role: "region", "aria-label": "subject", onClick: props.brandCallback },
            React.createElement(React.Fragment, null,
                React.createElement(Logo, __assign({}, __assign(__assign({}, brand), { iconOnly: brand.iconOnly || sidebar }))),
                slogan && (React.createElement(React.Fragment, null,
                    React.createElement("br", null),
                    React.createElement("span", { className: classMap("mpng-ux-header", sloganClassName !== null && sloganClassName !== void 0 ? sloganClassName : "text-primary") }, slogan)))))),
        (collapsible && (React.createElement(React.Fragment, null,
            React.createElement(RBNavbar.Collapse, { id: collapseId, className: classMap("justify-content-between", sidebar ? "flex-column" : "", "nav-bar-mobile"), role: "region", "aria-label": "student progress" },
                opacityNavBar,
                content),
            React.createElement(RBNavbar.Toggle, { "aria-controls": collapseId })))) || React.createElement("div", { className: "d-flex justify-content-between flex-grow-1" }, content)));
};
export default Navbar;
