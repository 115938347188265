var BackgroundDescriptionEnum;
(function (BackgroundDescriptionEnum) {
    // Default initial assets
    BackgroundDescriptionEnum["LOGIN"] = "Branded pattern background";
    BackgroundDescriptionEnum["OCEAN"] = "Photo of an ocean with fishes";
    BackgroundDescriptionEnum["WAVES"] = "Photo of an ocean wave breaking with tunnel";
    BackgroundDescriptionEnum["PURPLE_PINWHEEL"] = "Branded pattern background";
    // K-5
    BackgroundDescriptionEnum["BLUE_SEA"] = "Cartoon image of underwater scenery";
    BackgroundDescriptionEnum["BLUE_SKY"] = "Cartoon image of sky with clouds and the sun";
    BackgroundDescriptionEnum["DARK_GALAXY"] = "Cartoon blue space image with planets";
    BackgroundDescriptionEnum["DEEP_DIVE"] = "Cartoon image of deep sea with underwater scenery";
    BackgroundDescriptionEnum["DESERT"] = "Cartoon image of a desert with cactuses";
    BackgroundDescriptionEnum["FOREST"] = "Cartoon image with rolling hills and blue sky";
    BackgroundDescriptionEnum["HAPPY_HILLS"] = "Cartoon image with rolling hills and blue sky";
    BackgroundDescriptionEnum["SUNSET"] = "Cartoon image of a sunset and hills";
    BackgroundDescriptionEnum["SUNSET_HILLS"] = "Cartoon image with rolling hills and sunset";
    BackgroundDescriptionEnum["SUNSPOT_GALAXY"] = "Cartoon image of burgandy space scene with planets";
    BackgroundDescriptionEnum["SUNNY_GALAXY"] = "Cartoon green space image with Sun, meteors, and stars";
    // 6-8
    BackgroundDescriptionEnum["MOUNTAIN_FALLS"] = "Image of mountains with a water fall";
    BackgroundDescriptionEnum["COSMOS"] = "Cosmic space background";
    BackgroundDescriptionEnum["WAVE"] = "Photo of an ocean wave";
    BackgroundDescriptionEnum["CACTI"] = "Illustration of various cacti on a pink background";
    BackgroundDescriptionEnum["WATER"] = "Underwater view";
    BackgroundDescriptionEnum["JELLYFISH"] = "Underwater jellyfish with pink hues";
    BackgroundDescriptionEnum["PINK_PATTERN"] = "Pink Pattern";
    BackgroundDescriptionEnum["DOG"] = "Profile of a dog on aqua background";
    BackgroundDescriptionEnum["PANDA"] = "Illustration of Panda on aqua background";
    BackgroundDescriptionEnum["COOL_CAT"] = "Photo of a cat with sunglasses on";
    // 9-12
    BackgroundDescriptionEnum["PURPLE_PATTERN"] = "Purple Pattern";
    BackgroundDescriptionEnum["BIG_WAVE"] = "Photo of a big wave";
    BackgroundDescriptionEnum["BRIGHT_SWATCHES"] = "Illustration of bright paint swatches on canvas";
    BackgroundDescriptionEnum["DEEP_SPACE"] = "Photo of a cosmic space scene";
    BackgroundDescriptionEnum["HAPPY_PUPPY"] = "Photo of puppy running";
    BackgroundDescriptionEnum["LEAVES"] = "Illustration of green leaves";
    BackgroundDescriptionEnum["NAP_TIME"] = "Photo of a puppy and kitten taking a nap together";
    BackgroundDescriptionEnum["SPACE_LIGHT"] = "Photo of space scene with bright light";
    BackgroundDescriptionEnum["SUNNY_STREAM"] = "Photo of a stream in the woods with sunrise";
    BackgroundDescriptionEnum["WATERFALL"] = "Illustration of scenic mountains with a sunset and waterfall";
    BackgroundDescriptionEnum["MOUNTAINS"] = "Photo of a mountain scene on coast";
    // Visual Action Backgrounds
    BackgroundDescriptionEnum["ENGLISH"] = "English";
    BackgroundDescriptionEnum["READING"] = "Reading";
    BackgroundDescriptionEnum["MATH"] = "Math";
    // Theme specific
    BackgroundDescriptionEnum["DEFAULT"] = "default";
    BackgroundDescriptionEnum["PRIMARY"] = "primary";
})(BackgroundDescriptionEnum || (BackgroundDescriptionEnum = {}));
export default BackgroundDescriptionEnum;
