var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import SizeEnum from "../../shared/enums/size.enum";
import classMap from "../../shared/utilities/classMap";
import eventHelper from "../../shared/utilities/eventHelper";
import FaIcon from "../fa-icon";
var Indicator = function (_a) {
    var icon = _a.icon, size = _a.size, className = _a.className, callback = _a.callback;
    var actionCallback = callback !== null && callback !== void 0 ? callback : null;
    return (React.createElement("div", __assign({}, eventHelper(actionCallback), { className: "mpng-ux-indicator" }),
        React.createElement(FaIcon, { icon: icon, squared: true, size: size || SizeEnum.MEDIUM, className: classMap("mpng-ux-indicator-icon", className) })));
};
export default Indicator;
